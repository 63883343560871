/* eslint-disable import/no-unresolved */
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { graphql } from "gatsby";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import Anchor from "../components/elements/Anchor";
import Layout from "../components/common/Layout";
import Seo from "../components/meta/Seo";
import { isBrowser } from "../utilities/checkEnvironment";

const Homepage = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const homepageSlider = page.data.homepage_slider;

  return (
    <>
      <Seo page={page} />
      <Layout bookNowUrl={page.data.book_now_url}>
        <div className="d-flex d-md-none flex-column p-3">
          {homepageSlider.map((slide) => {
            return (
              <div key={slide.title} className="mb-3 image-link">
                <Anchor href={slide.link.url}>
                  <GatsbyImage
                    image={slide.image.gatsbyImageData}
                    alt={slide.image.alt || "logo"}
                  />
                  <h2 className="image-caption">{slide.title}</h2>
                  <div className="overlay" />
                </Anchor>
              </div>
            );
          })}
        </div>
        {isBrowser && (
          <div
            className="carousel-wrapper d-none d-md-flex"
            style={{
              opacity: `${
                document.readyState === "interactive" ||
                document.readyState === "complete"
                  ? 1
                  : 0
              }`,
            }}
          >
            <Swiper
              modules={[Autoplay]}
              slidesPerView={3}
              speed={900}
              autoplay
              loop
            >
              {homepageSlider.map((slide) => {
                return (
                  <SwiperSlide key={slide.title}>
                    <Anchor href={slide.link.url} className="slide-link">
                      <GatsbyImage
                        image={slide.image.gatsbyImageData}
                        alt={slide.image.alt || "logo"}
                      />
                      <h3 className="image-caption">{slide.title}</h3>
                      <div className="overlay" />
                    </Anchor>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
      </Layout>
    </>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicHomepage {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        homepage_slider {
          image {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
          link {
            url
            target
            id
          }
          title
        }
        meta_description
        meta_title
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
      }
    }
  }
`;

export default Homepage;
